import React from 'react';
import './HowItWorksSection.css'; // Ensure this CSS file is created for additional styles

const steps = [
  { title: 'Enter your location', description: 'Specify where you are or where you want to go.', icon: '/Background/location.png' },
  { title: 'Get Recommendations', description: 'Receive personalized food itineraries.', icon: '/Background/recommandation.png' },
  { title: 'Enjoy Your Meals', description: 'Visit recommended places and enjoy your meals.', icon: '/Background/meal.png' },
];

const HowItWorksSection = () => {
  return (
    <section id="how-it-works" className="py-12 bg-gray-900 text-white">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-8">How It Works</h2>
        <div className="flex flex-col items-center space-y-8">
          {steps.map((step, index) => (
            <div key={index} className="flex items-center space-x-4 max-w-3xl mx-auto p-6 bg-gray-800 rounded-lg shadow-md transform transition-transform hover:scale-105 animate-fade-in">
              <div className="flex-shrink-0">
                <img src={step.icon} alt={step.title} className="h-16 w-16" />
              </div>
              <div className="text-left">
                <h3 className="text-xl font-bold mb-2">Step {index + 1}</h3>
                <h4 className="text-2xl font-semibold mb-2">{step.title}</h4>
                <p className="text-lg">{step.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorksSection;
