import React, { useEffect, useState } from 'react';
import './HeroSection.css'; // Ensure this CSS file is created for additional styles

const HeroSection = () => {
  const [colorIndex, setColorIndex] = useState(0);
  const colors = ['#FFFFFF', '#FFFF00', '#E0FFFF', '#D3D3D3'];

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 3000); // Change color every 3 seconds

    return () => clearInterval(interval);
  }, [colors.length]);

  return (
    <section
      className="bg-cover bg-center h-screen"
      style={{ backgroundImage: 'url(/Background/ezgif.com-animated-gif-maker.gif)' }}
    >
      <div className="flex flex-col items-center justify-center h-full bg-black bg-opacity-50">
        <h1 className="text-4xl font-bold animate-slide-in" style={{ color: colors[colorIndex] }}>
        AI Powered Bespoke Travel
        </h1>
        <p className="text-xl mt-4 animate-fade-in" style={{ color: colors[colorIndex] }}>
          Find the best places to eat for breakfast, lunch, and dinner with our AI-powered recommendations.
        </p>
        <div className="mt-8">
          <a href="https://app.travelengine.ai" className="fancy-button">Go to App</a>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
