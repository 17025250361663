import React from 'react';

const features = [
  { title: 'Easy Search', description: 'Effortlessly find the best food spots near you.', icon: '🔍' },
  { title: 'Quick AI Searching', description: 'Fast and intelligent recommendations tailored to your taste.', icon: '⚡' },
  { title: 'Accurate Results', description: 'Reliable and precise results to ensure a great dining experience.', icon: '✅' },
];

const FeaturesSection = () => {
  return (
    <section id="features" className="py-12 bg-gray-100">
      <div className="container mx-auto text-center">
        <h2 className="text-3xl font-bold mb-8">Features</h2>
        <div className="flex justify-around space-x-4">
          {features.map((feature, index) => (
            <div
              key={index}
              className="max-w-xs p-6 bg-white rounded-lg shadow-md transform transition-transform hover:scale-105"
            >
              <div className="text-5xl">{feature.icon}</div>
              <h3 className="text-xl font-semibold mt-4">{feature.title}</h3>
              <p className="mt-4 text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
